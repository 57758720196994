import { API_URI } from '.'
import ArrayPayload from '../models/ArrayPayload'
import Entity from '../models/Entity'
import Package from '../models/Package'
import { getHeaders } from './auth'

export const getPackages = async (): Promise<ArrayPayload<Package>> => {
	const response = await fetch(
		`${API_URI}/packages?populate=logo&sort[0]=priority:desc`,
		{
			method: 'GET',
			headers: getHeaders(),
		}
	)

	return response.json()
}

export const getPackage = async (urlPath: string): Promise<Entity<Package>> => {
	const response = await fetch(
		`${API_URI}/packages` +
			`?populate=logo&filters[urlPath][$eq]=${urlPath}` +
			`&sort[0]=priority:desc`,
		{
			method: 'GET',
			headers: getHeaders(),
		}
	)

	const json = await response.json()

	return json.data[0]
}

export const getHasTokenLeft = async (packageId: number): Promise<boolean> => {
	const response = await fetch(
		`${API_URI}/tokens/hasLeft?packageId=${packageId}`,
		{
			method: 'GET',
			headers: getHeaders(),
		}
	)

	return await response.json()
}
