import { PageProps } from 'gatsby'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useAsync } from 'react-use'
import styled from 'styled-components/macro'
import { getHasTokenLeft, getPackage } from '../../apiClients/packages'
import Heading from '../../components/Heading'
import Layout from '../../components/Layout'
import { LayoutContainer } from '../../components/LayoutContainer'
import { MarkdownRenderer } from '../../components/MarkdownRenderer'
import Spinner from '../../components/Spinner'
import StoreItemHeader, {
	propsFromPackage,
} from '../../components/StoreItemHeader'
import { useAppContext } from '../../context'
import Breadcrumb from '../../models/Breadcrumb'
import { SettingKey } from '../../models/GlobalSetting'
import Package from '../../models/Package'
import { buildMetaTitle } from '../../utils/meta'

export default function ShopPackage({ params, location }: PageProps) {
	const [loading, setLoading] = useState(true)
	const [pkg, setPackage] = useState<Package | null>(null)
	const [pkgId, setPackageId] = useState<number | null>(null)
	const [hasTokenLeft, setHasTokenLeft] = useState(true)
	const { getSettingValue } = useAppContext()

	useAsync(async () => {
		setLoading(true)
		const response = await getPackage(params.urlPath)
		if (response) {
			setPackageId(response.id)
			setPackage(response.attributes)
		}
		setLoading(false)
	}, [params.urlPath])

	useAsync(async () => {
		if (!pkgId) return
		const response = await getHasTokenLeft(pkgId)
		setHasTokenLeft(response)
	}, [pkgId])

	const breadcrumbs: Breadcrumb[] = [
		{ link: '/shop/', featureName: 'shop' },
		{ link: location.pathname, display: pkg?.name ?? params.urlPath },
	]

	const metaTitle = getSettingValue(SettingKey.META_TITLE)

	return (
		<Layout breadcrumbs={breadcrumbs} center>
			<Helmet>
				<title>{buildMetaTitle([pkg?.name, metaTitle])}</title>
			</Helmet>
			<Container>
				{loading && !pkg ? (
					<Spinner />
				) : pkg ? (
					<>
						<MarkdownRenderer
							header={
								<StoreItemHeader {...propsFromPackage(pkg, hasTokenLeft)} />
							}
							children={pkg.details ?? '# Description not available'}
						/>
					</>
				) : (
					<Heading>Package not found</Heading>
				)}
			</Container>
		</Layout>
	)
}

const Container = styled(LayoutContainer)`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 30px;
`
